import { Gender } from "app/api/type";

export const exampleTicket = {
  ticketWithDetails: {
    ticket: {
      id: "BIN-RX23052200016",
      symptom: null,
      note: null,
      openmrsId: "87f0e7a3-34d9-4b40-9630-a19d80d65a4b",
      isActive: true,
      isPaid: true,
      ticketCategoryName: "Online",
      patientId: "BIN-PT23052200001",
      issueTime: new Date("2023-05-22T07:22:20.765Z"),
      bookedFor: new Date("2023-05-24T07:11:11.000Z"),
      sourceLocationId: "94797727-4cab-4cf1-b960-3007301dcd35",
      assignedLocationId: "354ebbef-2988-40fc-8f88-f90ab65c9ffe",
      timeSlotDate: new Date("2023-05-24T07:11:11.000Z"),
      timeSlotLocationId: "354ebbef-2988-40fc-8f88-f90ab65c9ffe",
      timeSlotSerial: 0,
      userId: null
    },
    ticketCategory: {
      name: "Online",
      price: 10,
      openmrsId: "41679904-797e-4012-bbe9-eeeda1ab0b35"
    },
    sourceLocation: {
      id: "94797727-4cab-4cf1-b960-3007301dcd35",
      name: "Online Ticket Counter",
      email: null,
      description: "Online Counter",
      logo: "https://www.shutterstock.com/image-vector/cashier-icon-logo-isolated-sign-260nw-2035807985.jpg",
      contactNumber: "01302606005",
      openmrsId: "eb7e2695-9ecc-456b-a0e1-4139527fa92f",
      tags: ["Counter"],
      locationCategoryName: "Counter",
      parentLocationId: "6bdad224-252f-493d-9dee-677ac1c5cdf0",
      shortAddress: "Online",
      addressId: null,
      createdAt: new Date("2023-05-22T00:35:40.477Z"),
      displayName: "Dhaka Medical College Hospital",
      timeSlotGeneratorStartTime: "08:00 AM",
      timeSlotGeneratorEndTime: "10:00 AM",
      wardTypeName: null
    },
    assignedLocation: {
      id: "354ebbef-2988-40fc-8f88-f90ab65c9ffe",
      name: "Dermatology and Venereology OPD",
      description: "",
      logo: "",
      email: null,
      contactNumber: "01302606005",
      openmrsId: "4d6a0ca6-a592-4054-9ab9-e480299ad404",
      tags: ["Department"],
      locationCategoryName: "Department",
      parentLocationId: "6bdad224-252f-493d-9dee-677ac1c5cdf0",
      shortAddress: "1st Floor (West Side)",
      addressId: null,
      createdAt: new Date("2023-05-22T00:35:39.580Z"),
      displayName: "Dhaka Medical College Hospital",
      timeSlotGeneratorStartTime: "08:00 AM",
      timeSlotGeneratorEndTime: "10:00 AM",
      wardTypeName: null
    },
    patient: {
      id: "BIN-PT23052200001",
      personId: "b93a3313-982c-4f0f-9ce1-12f8e9b772e4",
      patientCategoryName: "General",
      createdAt: new Date("2023-05-22T02:11:02.637Z")
    },
    person: {
      id: "b93a3313-982c-4f0f-9ce1-12f8e9b772e4",
      name: "Adib Abrar Kabeer",
      contactNumber: "01797130904",
      nationalIdNumber: null,
      passportNumber: null,
      openmrsId: "b926fced-e4a8-4232-aac3-46df50303953",
      gender: "male",
      fatherName: null,
      motherName: null,
      imageUrl: null,
      addressId: "98bc9c4f-0220-4e25-b4f2-17f6fbc361f4",
      dateOfBirth: new Date("1999-02-14T02:09:40.000Z"),
      isDateOfBirthEstimated: false,
      dateOfDeath: null,
      isDateOfDeathEstimated: false,
      createdAt: new Date("2023-05-22T02:11:02.637Z")
    }
  },
  hospitalLocation: {
    id: "6bdad224-252f-493d-9dee-677ac1c5cdf0",
    name: "Dhaka Medical College Hospital",
    description: "Hospital made for demo purposes",
    logo: "https://i.ibb.co/vw4rzqH/bindulogic.png",
    contactNumber: "0255165001",
    email: null,
    openmrsId: "1837ed6b-694f-4163-8fa9-51c495bd8547",
    tags: ["Hospital"],
    locationCategoryName: "Hospital",
    parentLocationId: "e9429e83-7e41-4c3f-99f9-7a241076c84b",
    shortAddress: "Secretariat Road, Shahbagh, Dhaka, Bangladesh",
    addressId: null,
    createdAt: new Date("2023-05-22T00:04:17.873Z"),
    displayName: "Dhaka Medical College Hospital",
    timeSlotGeneratorStartTime: "08:00 AM",
    timeSlotGeneratorEndTime: "10:00 AM",
    wardTypeName: null
  },
  printingLocation: {
    id: "94797727-4cab-4cf1-b960-3007301dcd35",
    name: "Online Ticket Counter",
    description: "Online Counter",
    email: null,
    logo: "https://www.shutterstock.com/image-vector/cashier-icon-logo-isolated-sign-260nw-2035807985.jpg",
    contactNumber: "01302606005",
    openmrsId: "eb7e2695-9ecc-456b-a0e1-4139527fa92f",
    tags: ["Counter"],
    locationCategoryName: "Counter",
    parentLocationId: "6bdad224-252f-493d-9dee-677ac1c5cdf0",
    shortAddress: "Online",
    addressId: null,
    createdAt: new Date("2023-05-22T00:35:40.477Z"),
    displayName: "Dhaka Medical College Hospital",
    timeSlotGeneratorStartTime: "08:00 AM",
    timeSlotGeneratorEndTime: "10:00 AM",
    wardTypeName: null
  },
  printingPerson: {
    id: "b93a3313-982c-4f0f-9ce1-12f8e9b772e4",
    name: "Adib Abrar Kabeer",
    contactNumber: "01797130904",
    nationalIdNumber: null,
    passportNumber: null,
    fatherName: null,
    motherName: null,
    imageUrl: null,
    openmrsId: "b926fced-e4a8-4232-aac3-46df50303953",
    gender: "male" as Gender,
    addressId: "98bc9c4f-0220-4e25-b4f2-17f6fbc361f4",
    dateOfBirth: new Date("1999-02-14T02:09:40.000Z"),
    isDateOfBirthEstimated: false,
    dateOfDeath: null,
    isDateOfDeathEstimated: false,
    createdAt: new Date("2023-05-22T02:11:02.637Z")
  }
};
