import React from "react";
import { Center, Stack } from "@chakra-ui/react";
import {
  Text,
  StyleSheet,
  Document,
  Font,
  Page,
  View,
  Image,
  PDFViewer
} from "@react-pdf/renderer";
import { PrintableOpdTicketComponent } from "../components/printable-opd-ticket.component";
import { exampleTicket } from "../constants/example-ticket.constant";
import { Person } from "app/api/type";

interface PrescriptionTestPageProps {}

export const PrescriptionTestPage: React.FC<PrescriptionTestPageProps> = (
  props: PrescriptionTestPageProps
) => {
  return (
    <Center width="100%">
      <Stack width={{ base: "100%", lg: "1200px" }}>
        <Stack justifyContent={"space-between"} height="95vh">
          <Stack spacing="12" py="4">
            <PDFViewer width={"100%"} height="1000px">
              <PrintableOpdTicketComponent
                ticketWithDetails={exampleTicket.ticketWithDetails}
                patientWithDetails={{
                  patient: exampleTicket.ticketWithDetails.patient,
                  person: exampleTicket.ticketWithDetails.person as Person
                }}
                hospitalLocation={exampleTicket.hospitalLocation}
                printingLocation={exampleTicket.printingLocation}
                printerPerson={exampleTicket.printingPerson}
              />
            </PDFViewer>
          </Stack>
        </Stack>
      </Stack>
    </Center>
  );
};
