import {
  Button,
  Center,
  Flex,
  Heading,
  Spinner,
  Stack,
  Tag,
  Text
} from "@chakra-ui/react";
import { PDFDownloadLink } from "@react-pdf/renderer";
import { PatientWithDetails, TicketWithDetails } from "app/api/type";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useGetHospitalQuery, useGetTicketByIdForPatientQuery } from "../api";
import { PrintableOpdTicketComponent } from "./printable-opd-ticket.component";

interface TicketDetailsComponentProps {
  ticketId: string;
}

export const TicketDetailsComponent: React.FC<TicketDetailsComponentProps> = (
  props: TicketDetailsComponentProps
) => {
  const navigate = useNavigate();
  const getTicketResult = useGetTicketByIdForPatientQuery({
    ticketId: props.ticketId
  });

  const getHospitalResult = useGetHospitalQuery({});

  const [ticket, setTicket] = useState<{
    ticketWithDetails: TicketWithDetails;
    patientWithDetails: PatientWithDetails;
  } | null>(null);

  useEffect(() => {
    if (getTicketResult.isSuccess) {
      setTicket(getTicketResult.data.ticket);
    }
  }, [getTicketResult]);

  const InformationRow = (informationRowProps: {
    label: string;
    value: string;
  }) => {
    return (
      <Flex justifyContent={"space-between"}>
        <Text fontWeight="bold">{informationRowProps.label}</Text>
        <Text>{informationRowProps.value}</Text>
      </Flex>
    );
  };

  return (
    <Stack spacing={8}>
      <Center>
        <Heading>E-Ticket</Heading>
      </Center>
      <Center>
        <Tag size="lg" colorScheme={"blue"}>
          {props.ticketId}
        </Tag>
      </Center>

      <Center>
        {ticket ? (
          <Stack
            width={{ base: "95%", md: "90%", lg: "85%" }}
            spacing={4}
            border="1px"
            borderColor="gray.300"
            borderRadius="md"
            p={4}
            textAlign="left"
          >
            <InformationRow
              label="Issue Time:"
              value={dayjs(ticket.ticketWithDetails.ticket.issueTime).format(
                "hh:mm a, MMM DD, YYYY"
              )}
            />
            <InformationRow
              label="Booked For:"
              value={dayjs(ticket.ticketWithDetails.ticket.bookedFor).format(
                "MMM DD, YYYY"
              )}
            />
            <InformationRow
              label="Assigned Department:"
              value={ticket.ticketWithDetails.assignedLocation.name}
            />
            <InformationRow
              label="Patient ID:"
              value={ticket.patientWithDetails.patient.id}
            />
            <InformationRow
              label="Patient Name:"
              value={ticket.patientWithDetails.person.name || "-"}
            />
            <InformationRow
              label="Symptom:"
              value={ticket.ticketWithDetails.ticket.symptom || "-"}
            />

            <Stack
              direction="column"
              width="100%"
              alignItems={"center"}
              justify="center"
            >
              {ticket.ticketWithDetails.ticket.isPaid &&
              getHospitalResult.data ? (
                <PDFDownloadLink
                  document={
                    <PrintableOpdTicketComponent
                      ticketWithDetails={ticket.ticketWithDetails}
                      patientWithDetails={ticket.patientWithDetails}
                      hospitalLocation={getHospitalResult.data.location}
                      printingLocation={ticket.ticketWithDetails.sourceLocation}
                      printerPerson={ticket.patientWithDetails.person}
                    />
                  }
                  fileName={`${ticket.ticketWithDetails.ticket.id}.pdf`}
                >
                  {({ blob, url, loading, error }) =>
                    loading ? (
                      <Button
                        colorScheme="blue"
                        width="100%"
                        isLoading
                        isDisabled
                      >
                        Loading...
                      </Button>
                    ) : (
                      <Button colorScheme="blue" width="100%">
                        Print E-Ticket
                      </Button>
                    )
                  }
                </PDFDownloadLink>
              ) : (
                <Button colorScheme="blue" isDisabled>
                  Print E-Ticket
                </Button>
              )}
            </Stack>
          </Stack>
        ) : (
          <Center height="256px" width="100%">
            <Spinner />
          </Center>
        )}{" "}
      </Center>
      <Center>
        <Button
          width={{ base: "95%", md: "90%", lg: "85%" }}
          colorScheme={"gray"}
          onClick={() => {
            navigate("/");
          }}
        >
          Go to Homepage
        </Button>{" "}
      </Center>
    </Stack>
  );
};
